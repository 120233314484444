@import url(https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700&display=swap);
.counter {
  text-align: center;
}

.counter__title {
  font-size: 2.369rem;
  font-weight: 600;
}

.counter__subtitle {
  display: block;
  margin: 0.2em 0 1.333em;
  color: var(--secondary);
  font-weight: 100;
}

.counter__list {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding: 0;
  list-style: none;
}

.counter__item {
  position: relative;
  margin: 0 1em 2em;
}

.counter__value {
  position: absolute;
  top: 0;
  left: 0;
  display: block;
  width: 100%;
  height: 120px;
  line-height: 120px;
  font-size: 3.157rem;
}

.counter__label {
  display: block;
  margin-top: 0.5em;
  text-transform: uppercase;
}

.progress {
  -webkit-transform: rotate(-90deg);
          transform: rotate(-90deg);
}

.progress__meter,
.progress__value {
  fill: none;
}

.progress__meter {
  stroke: var(--light);
  opacity: 0.1;
}

.progress__value {
  stroke: var(--primary);
  stroke-linecap: round;
}

:root {
  --dark: #22252c;
  --light: #e7dfdd;
  --primary: #e52a6f;
  --secondary: #67aeca;
}
body {
  min-height: 100vh;
  background: #22252c;
  background: var(--dark);
  font-family: "Roboto Slab", sans-serif;
  font-size: 1rem;
  line-height: 1.45em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #e7dfdd;
  color: var(--light);

  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  color: #e52a6f;
  color: var(--primary);
  font-weight: 300;
  font-size: 4.209rem;
  line-height: 1.45em;
}

h2 {
  color: #e7dfdd;
  color: var(--light);
  font-weight: 300;
  font-size: 3.157rem;
  line-height: 1.45em;
}

/*
 * 4.209
 * 3.157
 * 2.369
 * 1.777
 * 1.333
 * 1
 */

