@import url("https://fonts.googleapis.com/css?family=Roboto+Slab:100,300,400,700&display=swap");

:root {
  --dark: #22252c;
  --light: #e7dfdd;
  --primary: #e52a6f;
  --secondary: #67aeca;
}
body {
  min-height: 100vh;
  background: var(--dark);
  font-family: "Roboto Slab", sans-serif;
  font-size: 1rem;
  line-height: 1.45em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: var(--light);

  display: flex;
  align-items: center;
  justify-content: center;
}

h1 {
  color: var(--primary);
  font-weight: 300;
  font-size: 4.209rem;
  line-height: 1.45em;
}

h2 {
  color: var(--light);
  font-weight: 300;
  font-size: 3.157rem;
  line-height: 1.45em;
}

/*
 * 4.209
 * 3.157
 * 2.369
 * 1.777
 * 1.333
 * 1
 */
